// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #77b81e;
  --white: #ffffff;

  --heading-title: 45px;
  --heading-title-mob: 25px;
}
.navbar {
  background-color: var(--white);
}
nav .logo {
  width: 200px;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
  font-size: larger;
}

@media (max-width: 768px) {
  nav .logo {
    width: 165px;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: medium;
  }

  footer .container .row .logo img {
    width: 200px;
    height: 90px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/css/header.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;;EAEhB,qBAAqB;EACrB,yBAAyB;AAC3B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;AACF","sourcesContent":[":root {\n  --main-color: #77b81e;\n  --white: #ffffff;\n\n  --heading-title: 45px;\n  --heading-title-mob: 25px;\n}\n.navbar {\n  background-color: var(--white);\n}\nnav .logo {\n  width: 200px;\n}\n\n.navbar-light .navbar-nav .nav-link {\n  color: black;\n  font-size: larger;\n}\n\n@media (max-width: 768px) {\n  nav .logo {\n    width: 165px;\n  }\n  .navbar-light .navbar-nav .nav-link {\n    font-size: medium;\n  }\n\n  footer .container .row .logo img {\n    width: 200px;\n    height: 90px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
