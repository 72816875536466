// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div .about-content {
  padding: 0 150px;
}
div .about-content p {
  text-align: center;
  letter-spacing: 0.8px;
  line-height: 2.3;
}

div.topimg img{
  height: 50vh;
}
@media screen and (max-width: 768px) {
  div .about-content {
    padding: 0 30px;
  }
  div .about-content p {
    text-align: center;
    letter-spacing: normal;
    line-height: 1.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/css/about.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;EAClB;AACF","sourcesContent":["div .about-content {\n  padding: 0 150px;\n}\ndiv .about-content p {\n  text-align: center;\n  letter-spacing: 0.8px;\n  line-height: 2.3;\n}\n\ndiv.topimg img{\n  height: 50vh;\n}\n@media screen and (max-width: 768px) {\n  div .about-content {\n    padding: 0 30px;\n  }\n  div .about-content p {\n    text-align: center;\n    letter-spacing: normal;\n    line-height: 1.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
