// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-color: #77b81e;
  --white: #ffffff;

  --heading-title: 45px;
  --heading-title-mob: 25px;
}

.logo-slider .item {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.logo-slider .item button {
  border: none;
  text-decoration: none;
  text-transform: capitalize;
  color: black;
}
.logo-slider .item img {
  height: 150px;
  border-radius: 10px;
}
.logo-slider p {
  text-transform: uppercase;
  text-align: center;
  padding-top: 10px;
}

.slick-dots li.slick-active button:before {
  color: var(--main-color);
}
.sliick-dots li button:before {
  font-size: 12px;
}

.slick-next:before,
.slick-prev:before {
  color: #77b81e;
  font-size: 30px;
}

.item:hover {
  display: block;
  transition: all ease 0.3s;
  transform: scale(1) translateY(5px);
}

@media screen and (max-width: 768px) {
  .slick-next,
  .slick-prev {
    width: 50px;
    margin-right: 15px;
  }
  .logo-slider p {
    font-size: 10px;
  }
  .logo-slider .item {
    background-color: #fff;
    border-radius: 8px;
  }
  .logo-slider .item img {
    height: 100px;
    border-radius: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/component/css/slider.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;;EAEhB,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,YAAY;EACZ,qBAAqB;EACrB,0BAA0B;EAC1B,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,eAAe;AACjB;;AAEA;;EAEE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,mCAAmC;AACrC;;AAEA;EACE;;IAEE,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,eAAe;EACjB;EACA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;AACF","sourcesContent":[":root {\n  --main-color: #77b81e;\n  --white: #ffffff;\n\n  --heading-title: 45px;\n  --heading-title-mob: 25px;\n}\n\n.logo-slider .item {\n  background-color: #fff;\n  border-radius: 10px;\n  padding: 10px;\n}\n.logo-slider .item button {\n  border: none;\n  text-decoration: none;\n  text-transform: capitalize;\n  color: black;\n}\n.logo-slider .item img {\n  height: 150px;\n  border-radius: 10px;\n}\n.logo-slider p {\n  text-transform: uppercase;\n  text-align: center;\n  padding-top: 10px;\n}\n\n.slick-dots li.slick-active button:before {\n  color: var(--main-color);\n}\n.sliick-dots li button:before {\n  font-size: 12px;\n}\n\n.slick-next:before,\n.slick-prev:before {\n  color: #77b81e;\n  font-size: 30px;\n}\n\n.item:hover {\n  display: block;\n  transition: all ease 0.3s;\n  transform: scale(1) translateY(5px);\n}\n\n@media screen and (max-width: 768px) {\n  .slick-next,\n  .slick-prev {\n    width: 50px;\n    margin-right: 15px;\n  }\n  .logo-slider p {\n    font-size: 10px;\n  }\n  .logo-slider .item {\n    background-color: #fff;\n    border-radius: 8px;\n  }\n  .logo-slider .item img {\n    height: 100px;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
